import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#e86556",
      light: "#feedee",
      dark: "#b03925",
    },
    secondary: {
      main: "#9c27b0",
    },
  },
  typography: {
    h1: {
      fontSize: "3.75rem",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": { color: "#e86556" },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          "&:hover": { color: "#ffffff" },
        },
      },
    },
  },
});

// Palette:
// "#e86556"
// "#e96c54"
// "#ea7351"
// "#eb7a4f"
// "#ec814c"
// "#ed884a"
// "#ee8f47"
