import React from "react";

// MUI
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./src/theme";
import { CssBaseline } from "@mui/material";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {element}
  </ThemeProvider>
);
